import React from "react"
import Layout from "../components/layout"
import {
  Title,
  Overview,
  Toolset,
  Involvement,
  Visit,
  WorkedWith,
  Project,
  Contributions,
  Car,
} from "../components/parts"

const Assembled = () => {
  return (
    <Layout title="Assembled">
      <Title heading="Assembled" sub="Modern workforce management" />
      <Overview
        project="assembled"
        text={[
          "Assembled provides scheduling, forecasting, and analytics for contact centers and BPOs. It helps them make optimal staffing decisions, gain visibility into their performance and empowers the support agents with scheduling flexibility.",
          "I have made significant contributions to the front-end development & designs of the core product experience and multiple product features. In addition, I collaborated with fellow engineers, designers, business partners, and customers in Assembled. I also interviewed candidates for multiple front-end positions and mentored junior engineers through pair-programming sessions.",
        ]}
      />

      <Project
        heading="Staffing timeline"
        sub="Core of the product experience"
        style={{ marginBottom: 0 }}
      />
      <Contributions
        text={[
          "Scheduling and staffing timelines are at the core of Assembled's product experience.",
          "Significant contributions in redesigning and rewriting the staffing timeline to make it more usable, performant, and lovable.",
          "Gathered feedback from user interviews and set the vision of the new experience through a prototype.",
          "Key improvements that I envisioned/contributed to include the ability to drag and select multiple events, an intuitive copy-paste experience with instant preview across multiple rows, bulk add/edit events, zoom in/out, keyboard shortcuts, custom date ranges, prominent filters, agent search, and an overall sleeker visual interface.",
          "I collaborated & iterated with multiple team members and customers to gather feedback.",
        ]}
      />
      <div className="section-bottom">
        <Car
          images={[1, 2, 3, 4, 5, 6, 7, 8].map(
            i => `/images/assembled/timeline-${i}.png`
          )}
          bordered
          hideTop
        />
      </div>

      <Project heading="Agent schedule" sub="" style={{ marginBottom: 0 }} />
      <Contributions
        text={[
          "Agents check their schedule from here multiple times throughout their day, and this page is the most visited in the app.",
          "I solo shipped a redesign & rewrite of this page to upgrade the user experience to match the staffing timeline.",
          "Notable improvements include bringing in easy-to-use date pickers, a consistent way of creating & editing events, a more prominent display of agent's information including timezone, all new monthly view, undo & redo of user actions, keyboard shortcuts, and a visual update to the user interface.",
          "Handled the rollout of this page seamlessly while fixing user feedback along the way",
        ]}
      />
      <div className="section-bottom">
        <Car
          images={[1, 2, 3].map(i => `/images/assembled/agent-${i}.png`)}
          bordered
          hideTop
        />
      </div>

      <Project heading="Agent flexibility" sub="" style={{ marginBottom: 0 }} />
      <Contributions
        text={[
          "Assembled enables the agents to indicate their preferences or changes to their schedule.",
          "Authored the critical component - a preview of the proposed changes and to the actions page where managers can approve/deny the requests.",
        ]}
      />
      <div className="section-bottom">
        <Car
          images={[1, 2, 3].map(i => `/images/assembled/flex-${i}.png`)}
          bordered
          hideTop
        />
      </div>

      <Project
        heading="Adjustment to forecasting"
        sub=""
        style={{ marginBottom: 0 }}
      />
      <Contributions
        text={[
          "Forecast models cannot account for one-off events like product launches / unexpected outages, which spikes the number of tickets. This tool helps the user provide manual inputs to the forecast model.",
          "I was responsible for designing and implementing the tool in the front end.",
        ]}
      />
      <div className="section-bottom">
        <Car
          images={[1, 2, 3].map(i => `/images/assembled/forecast-${i}.png`)}
          bordered
          hideTop
        />
      </div>

      <Project
        heading="Holiday cards"
        sub="Customized cards for customers"
        style={{ marginBottom: 0 }}
      />
      <div className="section-bottom">
        <Car
          images={[1, 2].map(i => `/images/assembled/holiday-card-${i}.jpg`)}
          bordered
        />
      </div>

      <Project
        heading="Jar of appreciation"
        sub="Happy fuzzies from my team"
        style={{ marginBottom: 0 }}
      />
      <div className="section-bottom">
        <img src="/images/assembled/happy-jar.png" alt="happy-jar" />
      </div>

      <Toolset project="assembled" />
      <Involvement project="assembled" />
      <Visit project="assembled" />
      <WorkedWith
        project="assembled"
        peopleKey="people-engineers"
        label="Engineers I worked with"
      />
      <WorkedWith
        project="assembled"
        peopleKey="people-design"
        label="Designers"
      />
      <WorkedWith
        project="assembled"
        peopleKey="people-business"
        label="Business partners"
      />
    </Layout>
  )
}

export default Assembled
